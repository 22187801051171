import { useEffect, useState } from 'react';
import './App.css';
import QRCode from "react-qr-code";
import Lottie from "lottie-react";
import congratsAnimation from "./anim/congrats.json";
import errorAnimation from "./anim/error.json";
import loader from './loader.gif';

enum PaymentMethods {
  PAYBROKERS = "Paybrokers",
  SAFEWAY = "SafeWay_QR",
  ZENET_PAY = "ZenetPay",
  PAY2FREE = "Pay2FreePix",
  PIXTOPAY = "PixtoPay",
  PAGFAST = "PagFast"
}

function App() {

  const [loading, setLoading] = useState<boolean>(true);
  const [qrCode, setQrCode] = useState<string>("");
  const [amount, setAmount] = useState<string>("");

  const [congrats, setCongrats] = useState<boolean>(false);

  const [isCopied, setIsCopied] = useState<boolean>(false);

  const [error, setError] = useState<any>(null);

  const searchParams = new URLSearchParams(document.location.search);

  const fetchData = async (code: string) => {
    const request = await fetch("https://api.thesafeway.com.br:8443/pixs/pay-page/" + code);
    
    if(request) {
      if(request.status !== 200 && request.status !== 201) {
        const requestJson = await request.json();
        setError(requestJson.message);
        setLoading(false);
      } else {
        const requestJson = await request.json();
        setQrCode(requestJson.paymentText);
        setAmount(requestJson.value);
        setLoading(false);
      }
    }
  }

  function copyToClipboard(message: string) {
    const el = document.createElement("textarea");
    el.value = message;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    el.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(el);
    setIsCopied(true);
}

  useEffect(() => {
    window.kwaiq.instance('545856396815507499').track('purchase');
  }, []);

  useEffect(() => {
    const method = searchParams.get("method");
    const paymentSuccess = searchParams.get("success");

    if(paymentSuccess) {
      setCongrats(true);
      setLoading(false);
    }

    if(method) {
      if(method === PaymentMethods.PAYBROKERS || method === PaymentMethods.ZENET_PAY || method === PaymentMethods.PAY2FREE || method === PaymentMethods.PIXTOPAY || method === PaymentMethods.PAGFAST) {
        const add = searchParams.get("address");
        const amountValue = searchParams.get("amount");

        if(add && amountValue) {
          setQrCode(add);
          setAmount(amountValue);
          setLoading(false);
        }
      }

      if(method === PaymentMethods.SAFEWAY) {
        const code = searchParams.get("code");
        if(code) {
          fetchData(code);
        }
      }
    }
  }, []);

  return (
    <div className="App">
      <div className="header">
        <img src="https://r7.bet/logo.png" alt={"logo-r7"} />
      </div>

      {!error && congrats &&
         <div className="container">
            <h1 className="paymentSuccess">Pagamento Aprovado!</h1>
            <Lottie loop={false} animationData={congratsAnimation} />
            <p className='paymentSuccessDesc'>
              Seu pagamento foi aprovado e o saldo já está disponível.
            </p>

            <div onClick={() => {
              console.log("SENDIND MESSAGE");
              window.top?.postMessage("update", "*");
            }} className='playButton'>Jogar</div>
         </div>
      }
      {!congrats && loading && 
        <div className="container">
            <img src={loader} alt={"loading"} className="load" />
        </div>
      }
      {error &&
      <div className="container">
        <h3>Ocorreu um erro</h3>
        <Lottie loop={false} style={{ maxWidth: 150 }} animationData={errorAnimation} />
        <p className='errorMessage'>
          Conta com bloqueio administrativo. Por favor, entre em contato com o suporte.
        </p>
      </div>
      }
      {!error && !congrats && !loading && <div className="container">
          <div className='space-between'>
            <p>Valor:</p>
            <p>R${amount}</p>
          </div>
          <div className='deposit-Message'>
            <div className='attention-message'>❗️Atenção❗️</div>
            <div className='warningMessage'>O pagamento deve ser feito pelo mesmo TITULAR da conta R7Bet!</div>
          </div>
          {qrCode && <QRCode value={qrCode} className='qrCode' />}
          <div onClick={() => {
            copyToClipboard(qrCode);
          }} className='containerInput'>
            <input disabled={true} type="text" value={qrCode} className="inputQrcode" />
          </div>
          <div onClick={() => {
            copyToClipboard(qrCode);
          }} className="buttonCopy">
            {isCopied ? "COPIADO!" : "COPIAR A CHAVE PIX"}
          </div>

          <div className="waitingPayment">
            <img src={loader} alt={"loading"} />
            <p>Aguardando Pagamento...</p>
          </div>
      </div>}
    </div>
  );
}

export default App;
